var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "삭제", icon: "remove" },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "저장", icon: "save" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            type: "edit",
                            required: true,
                            comboItems: _vm.col0Items,
                            itemText: "codeName",
                            itemValue: "code",
                            label: "배출시설코드",
                            name: "col1",
                          },
                          model: {
                            value: _vm.data.col1,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col1", $$v)
                            },
                            expression: "data.col1",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "배출시설 명",
                            name: "col2",
                          },
                          model: {
                            value: _vm.data.col2,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col2", $$v)
                            },
                            expression: "data.col2",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            type: "edit",
                            required: true,
                            comboItems: _vm.col2Items,
                            itemText: "codeName",
                            itemValue: "code",
                            label: "배출활동",
                            name: "col02",
                          },
                          model: {
                            value: _vm.data.col02,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col02", $$v)
                            },
                            expression: "data.col02",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "소규모 배출시설",
                            name: "col3",
                          },
                          model: {
                            value: _vm.data.col3,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col3", $$v)
                            },
                            expression: "data.col3",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "시설용량 값",
                            name: "col4",
                            type: "number",
                          },
                          model: {
                            value: _vm.data.col4,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col4", $$v)
                            },
                            expression: "data.col4",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            comboItems: _vm.tierItems1,
                            type: "edit",
                            required: true,
                            itemText: "codeName",
                            itemValue: "code",
                            label: "시설용량 단위",
                            name: "col5",
                          },
                          model: {
                            value: _vm.data.col5,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col5", $$v)
                            },
                            expression: "data.col5",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "세부시설용량 값",
                            name: "col6",
                            type: "number",
                          },
                          model: {
                            value: _vm.data.col6,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col6", $$v)
                            },
                            expression: "data.col6",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            comboItems: _vm.tierItems1,
                            type: "edit",
                            required: true,
                            itemText: "codeName",
                            itemValue: "code",
                            label: "세부시설용량 단위",
                            name: "col7",
                          },
                          model: {
                            value: _vm.data.col7,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col7", $$v)
                            },
                            expression: "data.col7",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: { label: "가동일자", name: "col8" },
                          model: {
                            value: _vm.data.col8,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col8", $$v)
                            },
                            expression: "data.col8",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "일일 평균가동시간",
                            name: "col9",
                            type: "number",
                            suffix: "h/d",
                          },
                          model: {
                            value: _vm.data.col9,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col9", $$v)
                            },
                            expression: "data.col9",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "연간 가동일수",
                            name: "col10",
                            type: "number",
                            suffix: "d/y",
                          },
                          model: {
                            value: _vm.data.col10,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col10", $$v)
                            },
                            expression: "data.col10",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "부하율",
                            name: "col11",
                            type: "number",
                            suffix: "%",
                          },
                          model: {
                            value: _vm.data.col11,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col11", $$v)
                            },
                            expression: "data.col11",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            comboItems: _vm.tierItems2,
                            type: "edit",
                            required: true,
                            itemText: "codeName",
                            itemValue: "code",
                            label: "배출량에 따른 시설규모",
                            name: "col12",
                          },
                          model: {
                            value: _vm.data.col12,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col12", $$v)
                            },
                            expression: "data.col12",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-dept", {
                          attrs: {
                            required: true,
                            disabled: _vm.disabled,
                            label: "관리부서",
                            name: "deptCd",
                          },
                          model: {
                            value: _vm.data.deptCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "deptCd", $$v)
                            },
                            expression: "data.deptCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "할당대상여부",
                            name: "col13",
                          },
                          model: {
                            value: _vm.data.col13,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col13", $$v)
                            },
                            expression: "data.col13",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            comboItems: _vm.fuelItems3,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "신설/증설/폐쇄 여부",
                            name: "col14",
                          },
                          model: {
                            value: _vm.data.col14,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col14", $$v)
                            },
                            expression: "data.col14",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            label: "계획기간",
                            range: true,
                            name: "col15",
                          },
                          model: {
                            value: _vm.data.col15,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col15", $$v)
                            },
                            expression: "data.col15",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: { label: "발생일", name: "col16" },
                          model: {
                            value: _vm.data.col16,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col16", $$v)
                            },
                            expression: "data.col16",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "신설/증설/폐쇄 사유",
                            name: "col17",
                          },
                          model: {
                            value: _vm.data.col17,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col17", $$v)
                            },
                            expression: "data.col17",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
            [
              _c(
                "c-table",
                {
                  ref: "table",
                  attrs: {
                    title: "투입 원/연료",
                    tableId: "processManual",
                    columns: _vm.grid.columns,
                    gridHeight: _vm.grid.height,
                    data: _vm.grid.data,
                    columnSetting: false,
                    isFullScreen: false,
                    usePaging: false,
                    expandAll: true,
                    selection: "multiple",
                    rowKey: "col0",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            attrs: { label: "추가", icon: "add" },
                          }),
                          _c("c-btn", {
                            attrs: { label: "삭제", icon: "remove" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
            [
              _c(
                "c-table",
                {
                  ref: "table2",
                  attrs: {
                    title: "생산제품",
                    tableId: "processManual",
                    columns: _vm.grid2.columns,
                    gridHeight: _vm.grid2.height,
                    data: _vm.grid2.data,
                    columnSetting: false,
                    isFullScreen: false,
                    usePaging: false,
                    expandAll: true,
                    selection: "multiple",
                    rowKey: "col0",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            attrs: { label: "추가", icon: "add" },
                          }),
                          _c("c-btn", {
                            attrs: { label: "삭제", icon: "remove" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }